<template id="ColumnFilter">
    <div v-if="this.Elements">
        
        <div class="el-table-filter" style="position: absolute;  transform-origin: center top 0px; z-index: 2004;" x-placement="top-end">
            <div class="el-table-filter__content">
                <div class="el-scrollbar">
                    <div class="el-table-filter__wrap el-scrollbar__wrap" style="margin-bottom: -17px; margin-right: -17px;">
                        <div class="el-scrollbar__view">
                            <div role="group" aria-label="checkbox-group" class="el-checkbox-group el-table-filter__checkbox-group">

                                <template v-for="(Element, index) in this.FilterElements">                

                                    <label :key="index" class="el-checkbox">                    
                                        <span :class="Element.selected ? 'el-label-selected' : 'el-label'" @click="SelectFilterElement(Element.name)">
                                            {{Element.name}}
                                        </span>
                                    </label>

                                </template>
                            </div>
                        </div>
                    </div>
                <div class="el-scrollbar__bar is-horizontal">
            <div class="el-scrollbar__thumb" style="transform: translateX(0%);"></div>
            </div>
            <div class="el-scrollbar__bar is-vertical">
                    <div class="el-scrollbar__thumb" style="transform: translateY(0%);">
            </div>
        </div>
    </div></div>
        <div class="el-table-filter__bottom">
            <button @click="Confirm()"><b>Confirm</b></button>
            <button @click="Reset(false)">Reset</button>
            </div>
        </div>


        

    </div>
</template>

<style>
    .el-table-filter {
        border: 1px solid #ebeef5;
        border-radius: 2px;
        background-color: #fff;
        box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
        box-sizing: border-box;
        margin: 2px 0;
    }

    .el-table-filter__content {
        min-width: 100px;
    }

    .el-scrollbar {
        overflow: hidden;
        position: relative;
    }

    .el-scrollbar__wrap {
        overflow: scroll;
        height: 100%;
    }

    .el-table-filter__wrap {
        max-height: 280px;
    }

    .el-label-selected {
        display: inline-block;
        padding-left: 10px;
        line-height: 19px;
        font-size: 14px;
        color: #409eff;
    }

    .el-label {
        display: inline-block;
        padding-left: 10px;
        line-height: 19px;
        font-size: 14px;        
    }

    .el-checkbox__label {
        display: inline-block;
        padding-left: 10px;
        line-height: 19px;
        font-size: 14px;
        color: #409eff;
    }
    .el-checkbox {
        color: #606266;
        font-weight: 500;
        font-size: 14px;
        position: relative;
        cursor: pointer;
        display: inline-block;
        white-space: nowrap;
        user-select: none;
        margin-right: 30px;
    }


    .el-table-filter__checkbox-group label.el-checkbox {
        display: block;
        margin-right: 5px;
        margin-bottom: 8px;
        margin-left: 5px;
    }

    .el-checkbox-group {
        font-size: 0;
    }

    .el-table-filter__checkbox-group {
        padding: 10px;
    }

    .el-table-filter__bottom button {
        background: transparent;
        border: none;
        color: #606266;
        cursor: pointer;
        font-size: 13px;
        padding: 0 3px;
    }

    .el-table__column-filter-trigger {
        display: inline-block;
        line-height: 34px;
        cursor: pointer;
    }

</style>


<script>


    export default {
        name: 'ColumnFilter',
        props: {
            value: {
                //type: Number,
                required: true
            },
            Elements: {
                //type: Number,
                required: true
            }
            //ShowUndefined: {
            //    type: Boolean,
            //    required: true
            //},
        },
        data() {
            return {
                FilterElements: [],
            };
        },
        components: {
        },
        watch: {
            Elements: {
                immediate: true,
                deep: true,
                handler() {
                    this.Reset(true);                
                }
            }
        },

        methods: {
            test() {
                console.log('test');
            },

            AddFilterElement: function (parEntityElement, parSelect) {
                var tElement = {};
                tElement.name = parEntityElement;
                if (parSelect) {
                    if (this.value.find(e => e == parEntityElement)) {
                        tElement.selected = true;
                    }
                    else {
                        tElement.selected = false;
                    }
                }
                else {
                    tElement.selected = false;
                }

                this.FilterElements.push(tElement);

            },
            Confirm() {                
                //console.log('confirm');
                var tSelected = this.FilterElements.filter(e => e.selected === true).map(data => data.name);                        
                this.$emit('input', tSelected);
                this.$emit('change', tSelected);                
            },
            Reset(parSelect) {                
                this.FilterElements = [];
                this.Elements.forEach(element => this.AddFilterElement(element, parSelect));
            },
            SelectFilterElement: function (parEntityElement) {                
                this.FilterElements.find(e => e.name === parEntityElement).selected = !this.FilterElements.find(e => e.name === parEntityElement).selected;
            },
       

        },
        mounted() {
            console.log('mounted ColumnFilter');
        },
        updated() {
            //console.log(this.$route)
        }


    }


</script>