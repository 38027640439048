<template id="EditAna">

    <div>
        <!-- Errori -->
        <b-alert v-if="errors && errors.length" variant="danger" show dismissible>
            <ul>
                <li v-for="error of errors" :key="error.id">
                    Errore : {{error.message}}
                </li>
            </ul>
        </b-alert>
        
        <b-modal v-if="ShowModal"
                 id="id_modal_edit"
                 ref="modal"
                 size="lg"
                 @hidden="onClose"
                 @ok="onSubmit">

            <div slot="modal-title">
                {{SelectedEntity.label}}
                <b-badge v-if="New && !Multi" pill variant="success"><span>New</span></b-badge>
                <b-badge v-if="Multi" pill variant="success"><span>Multi</span></b-badge>
                <b-badge v-if="Clone" pill variant="success"><span>Clone</span></b-badge>
            </div>

            <!-- Errori -->
            <b-alert v-if="errors && errors.length" variant="danger" show dismissible>
                <ul>
                    <li v-for="error of errors" :key="error.id">
                        Errore : {{error.message}}
                    </li>
                </ul>
            </b-alert>
            
            <template v-for="(prop, index) in this.Elements">
                <b-form-group v-if="prop.visible != false"
                              :key="'ig_'+index"
                              :id="'ig_'+prop.csField"
                              label-cols-sm="4"
                              label-cols-lg="3"
                              :label="prop.label"
                              label-for="'input-'+index"
                              :horizontal="true">
                    <b-form-input v-if="prop.inputtype == 'varchar' " :key="'in_'+prop.csfield" :readonly="prop.readonly"
                                  :id="'input-'+index"
                                  v-model="pRow[prop.csfield.toLowerCase()]"
                                  type="text"></b-form-input>


                    <b-form-input v-if="prop.inputtype == 'int' " :key="'in_'+prop.csfield" :readonly="prop.readonly"
                                  :id="'input-'+index"
                                  v-model="pRow[prop.csfield.toLowerCase()]"
                                  type="text"></b-form-input>

                    <datetime v-if="prop.inputtype == 'date' "
                              v-model="pRow[prop.csfield.toLowerCase()]"
                              :id="'input-'+index"
                              class="form-control"
                              type="date"
                              value-zone="Europe/Rome">
                    </datetime>

                    <datetime v-if="prop.inputtype == 'datetime2' "
                              v-model="pRow[prop.csfield.toLowerCase()]"
                              :id="'input-'+index"
                              class="form-control"
                              type="datetime"
                              value-zone="Europe/Rome">
                    </datetime>



                    <BitProp v-if="prop.inputtype == 'bitprop'" v-model="pRow[prop.csfield.toLowerCase()]" v-bind:ShowUndefined="Multi"></BitProp>

                    <b-form-checkbox v-if="prop.sqltype == 'bit'" :key="'in_'+prop.csfield"
                                     :id="'cbinput-'+index"
                                     v-model="pRow[prop.csfield.toLowerCase()]"
                                     type="checkbox">
                    </b-form-checkbox>


                    <b-input-group v-if="prop.hasOwnProperty('reference') && !isLoading">
                        <AyListSelect v-if="prop.hasOwnProperty('reference')"
                                      :reference="prop.reference"
                                      style="width: 75%"
                                      :isDisabled=false
                                      :filters="GetComboFilters(prop.rifent)"
                                      v-model="pRow[prop.csfield.toLowerCase()]"
                                      @input="OnInput(pRow[prop.csfield.toLowerCase()],prop.reference)"
                                      @searchchange="UpdateSelectList(searchtext,'ciao')">
                        </AyListSelect>

                        <b-input-group-append>
                            <b-button title="Delete" size="xs" variant="outline-secondary" style="margin-left: 0px;" @click="OnDeleteComboElement(prop)">X</b-button>
                            <!-- Menù aggiuntivi-->
                            <b-dropdown id="dropdown-1" variant="link" toggle-class="text-decoration-none"  no-caret>
                                <template #button-content>
                                    <font-awesome-icon size="sm" icon="bars" :style="{ color: 'gray' }"/>
                                </template>
                                <b-dropdown-item @click="ShowAnaModal=true;OnGridView(prop)"> <font-awesome-icon size="sm" icon="table" style="margin-left: 4px;color : green;"/><span style="margin-left: 4px;color : green;">Grid view</span></b-dropdown-item>
                                <b-dropdown-item @click="OnNewComboElement(prop.reference,false,pRow[prop.csfield.toLowerCase()])"> <font-awesome-icon size="sm" icon="plus" style="margin-left: 4px;color : green;"/><span style="margin-left: 4px;color : green;">New</span></b-dropdown-item>
                                <b-dropdown-item v-if="pRow[prop.csfield.toLowerCase()]" @click="OnNewComboElement(prop.reference,true,pRow[prop.csfield.toLowerCase()])"> <font-awesome-icon size="sm" icon="copy" style="margin-left: 4px;color : gray;"/><span style="margin-left: 4px;color : gray;">Clone</span></b-dropdown-item>                                
                            </b-dropdown>
                        </b-input-group-append>


                    </b-input-group>


                    <!--<p v-if="prop.hasOwnProperty('reference') && isLoading">Loading...</p>-->

                </b-form-group>
            </template>

            <b-card-text class="text-danger">{{ErrorResponse}}</b-card-text>
            <AnaModal v-if="ShowAnaModal && RifPropViewGrid" :Rifentity="RifPropViewGrid.rifent" v-on:hidemodal="ShowAnaModal=false"  v-on:select="OnGridViewSelect"></AnaModal>


            <template v-slot:modal-footer>

                <div v-if="SwiDel">
                    <font-awesome-icon variant="danger" size="lg" icon="exclamation-triangle" style="color:red" /> <b>Are you sure ?  </b>
                    <b-button @click="onClose">
                        Cancel
                    </b-button>
                </div>
                <b-button v-if="!New" variant="danger" class="mr-auto" @click="onDelete">
                    Delete
                </b-button>

                <b-button title="Clear all selected values" v-if="!SwiDel" variant="light" @click="onClear">
                    X
                </b-button>
                <b-button v-if="!SwiDel" variant="success" @click="onSubmit2">
                    Save
                </b-button>
                <b-button v-if="!SwiDel" @click="onClose">
                    Cancel
                </b-button>



            </template>

        </b-modal>


    </div>

    

</template>

<style>

    .vdatetime-input {
        border: 0;
        text-align: center;
        width: 100%;
    }
</style>

<script>
    import axios from 'axios';    
    import BitProp from './BitProp.vue';
    import AyListSelect from './ay-list-select';    
    import AnaModal from './AnaModal';    
    //Attenzione : temporaneamente bloccato "luxon": "^1.25.0" per un bug
    import { Datetime } from 'vue-datetime';
    import 'vue-datetime/dist/vue-datetime.css'

    export default {
        components: {            
            AyListSelect,
            BitProp,
            Datetime,
            AnaModal
        },
        props: {
            id: {
                type: Number,
                required: true
            },
            Rifentity: {
                type: Number,
                required: true
            },
            Row: {
                type: Object,
                required: true
            },
            TemplateFilters: {
                type: Array,
                required: true
            },
            RowId: {
                type: String,
                required:true
            },
            New: {
                type: Boolean,
                required: true
            },
            Multi: {
                type: Boolean,
                required: true
            },
            Clone: {
                type: Boolean,
                required: false
            },
        },
        data() {
            return {                
                ComboFilters: [],
                errors: [],
                isLoading: true,
                ShowModal: false,
                Elements: Object,
                ErrorResponse: '',                
                ComboElements: [],           
                pRow: {},
                SelectedEntity: Object,
                SwiDel: false,
                RifPropViewGrid:{},                
                ShowAnaModal:false
            };
        },
        watch: {
            Rifentity: {
                immediate: true,
                handler(newVal) {
                    this.SwiDel = false;
                    this.isLoading = true;     
                    this.Elements = [];
                    this.ComboFilters = [];
                    //console.log('EditAna : watch Rifentity  ');
                    //console.log(newVal);
                    this.SelectedEntity = this.$store.state.Entity.find(E => E.id === this.Rifentity);

                    var tElements = this.$store.state.ElementsAll.filter(E => E.fkmanagedentity === newVal);

                    //console.log('Multi non va');
                    //console.log('Multi : ' + this.Multi);

                    if (this.Multi) {
                        var tMultiEl = [];
                        tElements.forEach(element => {
                            if (Object.prototype.hasOwnProperty.call(element, 'reference')) {
                                tMultiEl.push(element);
                            }
                            //Visualizzo anche bitprop
                            if (element.inputtype == 'bitprop') {
                                tMultiEl.push(element);
                            }
                            

                        });
                        tElements = tMultiEl;
                    }

                    //console inibisco temporaneamente
                    //this.GetAllComboData(tElements.filter(E => E.visible === true));
                    this.isLoading = false;

                    tElements.forEach(element => this.SetElementProps(element));

                    tElements = tElements.filter(e => e.visible == true);

                    this.Elements = tElements.sort((a, b) => (a.displayorder > b.displayorder) ? 1 : ((b.displayorder > a.displayorder) ? -1 : 0));
                                        
                    // Non rimuovere questo commento
                    console.log('tElements aggiunti');                    
                }
            },
            RowId: {
                immediate: true,
                handler(Val,oldVal) {
                    //console.log('ShowModal');                    
                    this.ShowModal = true;
                    //this.pRow = this.Row; 
                    this.pRow = JSON.parse(JSON.stringify(this.Row));
                    if (this.New) {
                        if (!this.Clone) {
                            //Riga nuova, mi serve il template
                            this.GetRow();
                        }
                    }               
                    if (Val !== oldVal) {
                        this.ComboFilters = [];
                        this.$bvModal.show('id_modal_edit');
                    }
                    this.$nextTick(function () {
                        this.$bvModal.show('id_modal_edit');
                    });   
                }                    
            },
            ShowModal: {
                immediate: true,
                handler(newVal) {
                    if (newVal == true) {
                        this.$bvModal.show('id_modal_edit');
                    }
                }
            }
        },
        methods: {
            GetEntity: function (parRifEntity) {
                // Ritorda la definizione della classe
                if (this.$store.state.Entity.find(E => E.id === parRifEntity)) {
                    //console.log('GetEntity trovato : ' + parRifEntity);
                    return this.$store.state.Entity.find(E => E.id === parRifEntity);
                }
                else {
                    console.log('GetEntity non trovato :' + parRifEntity);
                    return undefined;
                }

            },
            GetElements: function (RifEntity) {  
                // Ritorda la definizione della classe, se non � in memoria la scarica dal server
                if (this.$store.state.Elements[RifEntity]) {
                    //console.log('trovato : ' + RifEntity);
                    return this.$store.state.Elements[RifEntity];
                }
                else {
                    //console.log('non trovato :' + RifEntity);
                    //Scarico
                    let self = this;
                    self.isLoading = true;                    
                    var Link = 'api/_main/ManagedEntityElement/childof/' + RifEntity;
                    axios.get(Link,this.$store.state.Headers)
                        .then(response => {
                            var classdef = response.data.Result;
                            if (classdef.length > 0) {
                                this.$store.commit('SetElements', classdef);
                                self.isLoading = false;
                                return classdef;
                            }
                            else {
                                console.log('ManagedEntityElement vuoto');
                            }
                        }).catch(e => {
                            this.errors.push(e)
                            self.isLoading = false;
                        });

                }
                
            },     
            GetComboArray: function (SourceArray, Elements,KeyField) {
                var output = [];
                //console.log('KeyField :' + KeyField);                
                var tKeyName = Elements.find(E => E.id == KeyField).csfield.toLowerCase();                
                var tElDesc = Elements.filter(E => E.insummary == true);
                for (var i = 0; i < SourceArray.length; ++i) {
                    var tDesc = [];
                                        
                    tElDesc.forEach(element => {
                        //console.log(element.csfield);
                        tDesc.push(SourceArray[i][element.csfield.toLowerCase()]);
                    });
                    
                    var tRow = {};
                    tRow.code = SourceArray[i][tKeyName];
                    tRow.desc = tDesc.join(" | ");                    
                    output.push(tRow);                    
                }        
                //console.log(output);
                return output;
            },           
            GetComboFilters: function (parRifEnt) {

                console.log('GetComboFilters');
                return this.ComboFilters.filter(e => e.rifent === parRifEnt);
            },
            GetComboLinkReq2: function (parRifEntity) {
                let self = this;
                self.isLoading = true;
                var tRifEnt = parRifEntity.rifent;
                var tEntity = this.$store.state.Entity.find(E => E.id === tRifEnt);
                var Link = 'api/' + (self.SelectedEntity.fkcompany == -1 ? '_main' : this.$store.state.Company.csnamespace) + '/' + tEntity.csclass + '/summary/filtered';
                // Body
                var tReqBody = {};
                //Non voglio la paginazione, posso togliere i parametri ?
                tReqBody.pagination = {};
                tReqBody.pagination.pageNumber = 1;
                tReqBody.pagination.pageSize = 1000;
                tReqBody.pagination.includeCount = false;
                // Filtri

                
                var Filters = [];
                var tComboFilters = this.ComboFilters.filter(e => e.rifent === tRifEnt);
                
                tComboFilters.forEach(e => {
                
                    if (e.Value) {
                        var Filter = {};
                        Filter.managedEntityElement = e.managedEntityElement;
                        Filter.inverse = false;
                        Filter.bigintValues = e.bigintValues;
                        Filter.nvarcharValues = e.nvarcharValues;
                        //var Filters = [];
                        console.log('aggiungo filtro');
                        console.log(Filter);
                        Filters.push(Filter);
                    }
                });
                tReqBody.filters = Filters;
         

                var tReqData = {};
                tReqData.Link = Link;
                tReqData.ReqBody = tReqBody;
                tReqData.Elements = self.$store.state.ElementsAll.filter(E => E.fkmanagedentity === tRifEnt);                
                tReqData.RifEntity = parRifEntity;
                return tReqData;

            },          
            GetRow() {
                let self = this;  
                self.isLoading = true;                
                var Link = 'api/' + self.SelectedEntity.controller + '/createtemplate/';
                axios.get(Link, this.$store.state.Headers)
                    .then(response => {
                        self.pRow = response.data.Result;

                        //Metto i valori di default nel template partendo dai filtri in elenco
                        let tValue = undefined;
                        self.TemplateFilters.forEach(tFilter => {
                            if (tFilter.bigintValues.length == 1) {
                                tValue = tFilter.bigintValues[0];
                            }
                            if (tFilter.nvarcharValues.length == 1) {
                                tValue = tFilter.nvarcharValues[0];
                            }
                            if (tValue) {
                                var tProp = this.$store.state.ElementsAll.find(E => E.id === tFilter.managedEntityElement);
                                //Se non lo trovi non puoi aggiornare !
                                if (Object.prototype.hasOwnProperty.call(self.pRow, tProp.csfield.toLowerCase())) {
                                    self.pRow[tProp.csfield.toLowerCase()] = tValue;
                                }
                            }
                            
                        });

                        //Azzero le date se nuovo record
                        if (self.New) {
                            self.Elements.filter(e => e.inputtype == 'date').forEach(element => {
                                self.pRow[element.csfield.toLowerCase()] = undefined;
                            });
                        }

                        //Se sono in multi metto in "grigio" il bitprop
                        if (self.Multi) {                        
                            self.Elements.filter(e => e.inputtype == 'bitprop').forEach(element => {                                
                                self.pRow[element.csfield.toLowerCase()] = undefined;
                            });
                        }


                        self.isLoading = false;
                    });
            },
            GetUpdatedComboData(parReqList) {
                console.log('GetUpdatedComboData');
                let self = this;
                var i;        
                self.isLoading = true;
                axios.all(parReqList.map(R => axios.post(R.Link, R.ReqBody,this.$store.state.Headers)))
                    .then(axios.spread(function (...responses) {
                        // all requests are now complete
                        for (i = 0; i < responses.length; i++) {
                            // da mettere asincrono ? 
                            var tReqList = parReqList[i];
                            self.ComboElements[tReqList.RifEntity.rifent] = self.GetComboArray(responses[i].data.Result, tReqList.Elements, tReqList.RifEntity.reference);
                            //self.Row[tReqList.RifEntity.csfield.toLowerCase()] = undefined;
                        }
                        self.isLoading = false;
                    }));

            },
            OnDeleteComboElement(parRifProp) {
                console.log('OnDeleteComboElement');                
                this.pRow[parRifProp.csfield.toLowerCase()] = undefined;
                this.UpdateCombo3(parRifProp.reference, undefined);
            },
            OnNewComboElement(parRifEntityElement, parSwiClone, parRifClone) {                                
                var tRif = 0;
                if (parSwiClone) {
                    tRif = parRifClone;
                }
                // Modifico passando come riferimento l'elemento e non l'entity, cos� so a che campo applicare il filtro
                this.$emit('newcomboelement', parRifEntityElement, tRif);                
            },
            OnInput(Value, RifElem) {
                //console.log('OnInput RifElem : ' + RifElem);
                this.UpdateCombo3(RifElem, Value);

                if (!this.Multi) {
                    // Nel caso di modifica di un valore metto automaticamente lo stato a confermato
                    var tArrayBitProps = this.Elements.filter(P => P.inputtype == 'bitprop');
                    tArrayBitProps.forEach(prop => {
                        this.pRow[prop.csfield.toLowerCase()] = 0;
                    });
                }
                
                
            },

            UpdateCombo3(RifElem, Value) {
                console.log('RifElem :' + RifElem);
     

                //Rimuovo eventuali filtri gi� impostati sull'elemento
                this.ComboFilters = this.ComboFilters.filter(e => e.RifElemOri !== RifElem);

                //Se non c'� Value non devo creare i filtri
                if ((Value == undefined) || (Value == null)) {
                    //console.log('No Value : non devo creare nuovi filtri');
                    return;
                }

                //console.log('ComboFilters dopo : ' + this.ComboFilters.length);

                var tElement = this.$store.state.ElementsAll.find(E => E.id === RifElem);
                //var tReqList = [];
                //console.log(tElement);
                var tKey = tElement.fkmanagedentity;
                // Cerco gli elementi che lo referenziano
                this.$store.state.ElementsAll.filter(E => E.fkmanagedentity === tKey).forEach(element => {
                    //Cerco gli elementi con un riferimento
                    this.$store.state.ElementsAll.filter(E => E.reference === element.id).forEach(element2 => {
                        //Cerco in Elements se ci sono riferimenti                           
                        this.Elements.filter(E => E.rifent === element2.fkmanagedentity).forEach(element3 => {
                            //console.log(element2.id);
                            //console.log(element3.reference);

                            //
                            var Filter = {};
                            Filter.RifElemOri = RifElem; // per sapere origine
                            Filter.rifent = element3.rifent;  // id oggetto dest
                            Filter.RifElemDes = element2.id; // per sapere destinazione
                            Filter.ElemDes = element3; // per sapere destinazione
                            //Filter.managedEntityElement = element2.id;
                            Filter.managedEntityElement = RifElem;
                            Filter.inverse = false;
                            Filter.bigintValues = [];
                            Filter.nvarcharValues = [];

                            //console.log('guarda value : ' + Value);
                            
                            //var tSqltype = element2.sqltype;
                            var tSqltype = tElement.sqltype;
                            //console.log(tSqltype);
                            if ((Value) && (Value != null)) {
                                //console.log('guarda value : ' + Value);
                                if ((tSqltype == 'varchar') || (tSqltype == 'nvarchar')) {
                                    Filter.nvarcharValues.push(Value);
                                    //console.log('Filter.nvarcharValues');
                                }
                                else {
                                    Filter.bigintValues.push(Value);
                                    //console.log('Filter.bigintValues');
                                }
                                Filter.Value = Value; //serve per sapere se il filtro � valorizzato

                            
                            }
                            this.ComboFilters.push(Filter);
                            

                        });
                    });
                });
            },
            GetTemplate() {
                let self = this;
                self.isLoading = true;

                //console.log('GetTemplate');

                var Link = 'api/' + self.SelectedEntity.controller + '/updatetemplate/';
                if (this.New && !this.Clone) {
                    Link = 'api/' + self.SelectedEntity.controller + '/createtemplate/';
                }                            

                axios.get(Link, this.$store.state.Headers)
                    .then(response => {
                        var tTemplate = response.data.Result;
                        //console.log('tTemplate',tTemplate);
                        self.UpdateRec(self.pRow, tTemplate);
                        self.isLoading = false;
                    });
            },
            UpdateRec(parRec,parTemplate) {
				let self = this;
                var tKeys = Object.keys(parTemplate);
                //console.log('UpdateRec tKeys');
                //console.log(tKeys);
                //console.log(parRec);
                tKeys.forEach(Key => {
                    if (Object.prototype.hasOwnProperty.call(parRec, Key)) {
                        parTemplate[Key] = parRec[Key];
                    }
                    else {
                        //Metto i valori di default nel template partendo dai filtri in elenco
                        let tValue = undefined;
                        self.TemplateFilters.forEach(tFilter => {
                            if (tFilter.bigintValues.length == 1) {
                                tValue = tFilter.bigintValues[0];
                            }
                            if (tFilter.nvarcharValues.length == 1) {
                                tValue = tFilter.nvarcharValues[0];
                            }
                            if (tValue) {
                                var tProp = this.$store.state.ElementsAll.find(E => E.id === tFilter.managedEntityElement);
                                if (tProp.csfield.toLowerCase() == Key) {
                                    if (Object.prototype.hasOwnProperty.call(parTemplate, Key)) {
                                        parTemplate[Key] = tValue;
                                    }
                                }
                            }
                        });
                    }
                });
                //console.log('Chiamo OnSubmit');
                this.onSubmit();
            },
            UpdateSelectList(searchtext,prova) {
                console.log('UpdateSelectList ' + searchtext+' '+prova);
            },
            onClear() {
                //Elimina selezioni di tutti i combo
                this.Elements.forEach(element => {
                    if (Object.prototype.hasOwnProperty.call(element, 'reference')) {
                        this.pRow[element.csfield.toLowerCase()] = undefined;
                        this.UpdateCombo3(element.reference, undefined);
                    }
                });
                
            },
            onDelete() {
                if (!this.SwiDel) {
                    this.SwiDel = !this.SwiDel;
                }
                else {
                    //console.log('delete');
                    this.ErrorResponse = '';
                    let self = this;                    
                    var Link = 'api/' + (self.SelectedEntity.fkcompany == -1 ? '_main' : this.$store.state.Company.csnamespace) + '/' + self.SelectedEntity.csclass + '/' + self.RowId;
                    axios.delete(Link, this.$store.state.Headers)
                        .then(response => {
                            console.log('response delete');
                            if (response.data.isError) {
                                self.ErrorResponse = response.title;
                            }
                            this.$bvModal.hide('id_modal_edit');
                            var tResp = {};
                            tResp.SwiSaved = true;
                            tResp.SwiMulti = false;
                            tResp.Row = this.pRow;
                            tResp.Winid = this.id;
                            tResp.RifElement = this.
                            this.$emit('hidemodal', tResp);
                            //this.$emit('hidemodal', !response.data.isError, response.title);
                            this.SwiDel = false;
                        })
                        .catch(e => {                            
                            if (Object.prototype.hasOwnProperty.call(e.response.data, 'detail')) {                                
                                self.ErrorResponse = e.response.data.detail;
                            }
                            else {                                
                                self.ErrorResponse = e;                                                        
                            }                            
                            this.errors.push(e)
                        })
                }
                
            },
            OnGridView(rifProp)
            {
                this.RifPropViewGrid = rifProp;
                //this.RifEntityGrid = rifentity;
                //this.RifReferenceField = refField;
            },
            OnGridViewSelect(rifrec) {
                //Aggiorno il record corrente e la lista associata nel combo con il valore ricevuto dalla griglia apera in modale                
                var tElem = this.$store.state.ElementsAll.find(E => E.id === this.RifPropViewGrid.reference);                
                
                //Nuovo Valore ricevuto dalla griglia
                var tNewValue = rifrec[tElem.csfield.toLowerCase()];                
                
                //Aggiorno record in griglia
                this.pRow[this.RifPropViewGrid.csfield.toLowerCase()] = tNewValue;
                //Aggiorno elenco combo
                this.OnInput(tNewValue,this.RifPropViewGrid.reference);

                //Azzero oggetto di riferimento.
                this.ShowAnaModal = false;
                this.RifPropViewGrid = {};
                
            },          
            onSubmit2() {
                //console.log('onSubmit2');
                //Prima scaricao il Template e ci copio il record, poi lo invio per il salvataggio...
                this.GetTemplate();
            },
            onSubmit() {                
                let self = this;   
                this.errors.splice(0);                                             
                var Link = 'api/' + (self.SelectedEntity.fkcompany == -1 ? '_main' : this.$store.state.Company.csnamespace) + '/' + self.SelectedEntity.csclass;                

                var tResp = {};
                tResp.SwiSaved = true;
                tResp.SwiMulti = false;
                tResp.Row = this.pRow;
                tResp.Winid = this.id;
                //console.log('id :' + this.id);

                if (this.New) {
                    if (this.Multi) {
                        //MULTI                  
                        tResp.SwiMulti = true;
                        //console.log('Submit saving Multi');
                        this.$bvModal.hide('id_modal_edit');
                        this.$emit('hidemodal', tResp);
                        return;
                    }
                    else {
                        // NEW
                        console.log('posting new record !',Link);
                        axios.post(Link, self.pRow, this.$store.state.Headers)
                            .then(response => {
                                tResp.OK = response.data.OK;
                                
                                //Aggiungo la chiave ricevuta al record, i template ed i nuovi record non hanno la chiave
                                var tNewKey = response.data.Result;
                                var tKeyElement = this.$store.state.ElementsAll.find(E => E.fkmanagedentity ===  self.Rifentity && E.entitykey == true);
                                tResp.Row[tKeyElement.csfield.toLowerCase()] = tNewKey;

                                this.$bvModal.hide('id_modal_edit');
                                this.$emit('hidemodal', tResp);
                            })
                            .catch(e => {
                                //this.errors.push(e)
                                var tErr = Error();
                                if (e.response.data.status == 422) { // Unprocessable Entity
                                    if (Object.prototype.hasOwnProperty.call(e.response.data, 'validationErrors')) {
                                    tErr.message = "NEW failed : " + e.response.data.validationErrors[0].reason
                                    }
                                    else{
                                        tErr.message = "NEW failed with detail " + e.response.data.detail;
                                    }
                                }
                                else if (e.response.data.status == 500) { // Internal Server Error
                                    tErr.message = "NEW failed with status code " + e.response.data.status + " Detail : " + e.response.data.detail
                                }
                                else {
                                    tErr.message = "NEW failed with status code " + e.response.data.status;           
                                }
                                                           
                                this.errors.push(tErr);                            

                            })
                    }
                }
                else {
                    // EDIT
                    this.errors = [];
                    axios.put(Link, self.pRow, this.$store.state.Headers)
                        .then(response => {
                            tResp.OK = response.data.OK;
                            //console.log('a');
                            this.$bvModal.hide('id_modal_edit');
                            //console.log('b');
                            this.$emit('hidemodal', tResp);
                            //console.log('c');
                        })
                        .catch(e => {                        
                            var tErr = Error();
                            tErr.message = "Request failed with status code " + e.response.data.status;                            
                            this.errors.push(tErr);                            
                        })

                }

                

            },
            onClose() {
                this.isLoading = true;     
                this.ShowModal = false;
                var tResp = {};
                tResp.SwiSaved = false;
                tResp.SwiMulti = false;
                tResp.Row = this.pRow;
                tResp.Winid = this.id;
                this.$emit('hidemodal', tResp);                
            },
            SetElementProps: function (element) {
                //csfield: "BITPROP"
                element.inputtype = element.sqltype;

                if (element.inputtype == 'char') {
                    element.inputtype = 'varchar';
                }

                if (element.inputtype == 'nvarchar') {
                    element.inputtype = 'varchar';
                }

                if ((element.inputtype == 'decimal') || (element.inputtype == 'smallint') || (element.inputtype == 'tinyint') || (element.inputtype == 'numeric')) {
                    element.inputtype = 'int';
                }


                if (element.csfield.toLowerCase() == 'bitprop') {
                    element.inputtype = 'bitprop';
                }

                if (Object.prototype.hasOwnProperty.call(element, 'reference')) {
                    element.inputtype = 'reference';
                    //return this.$store.state.ElementsAll.find(E => E.id === element.reference).fkmanagedentity;
                    //var tElement = this.$store.state.ElementsAll.find(E => E.id === element.reference);
                    //var tKey = tElement.fkmanagedentity;      
                    element.rifent = this.$store.state.ElementsAll.find(E => E.id === element.reference).fkmanagedentity;
                    //element.rifent = this.$store.state.ElementsAll.find(E => E.id === element.reference).fkmanagedentity;
                }

            }
            
        },
        mounted() {
            
            
        }

    }
</script>
