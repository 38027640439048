<template id="FilerAna">


    <div class="container">
        <div class="col-md-8">
            <b-card-group>

                <b-card header="Filters"
                        header-text-variant="white"
                        header-tag="header"
                        header-bg-variant="dark"
                        img-top
                        tag="article"
                        style="max-width: 40rem;"
                        class="mb-4">

                    <!-- Errori -->
                    <b-alert v-if="errors && errors.length" variant="danger" show dismissible>
                        <ul>
                            <li v-for="error of errors" :key="error.id">
                                Errore : {{error.message}}
                            </li>
                        </ul>
                    </b-alert>


                    <template v-for="(prop, index) in this.Elements">

                        <b-form-group :key="'ig_'+index"
                                      id="'ig_'+prop.csField"
                                      label-cols-sm="4"
                                      label-cols-lg="3"
                                      :label="prop.label"
                                      label-for="input-1"
                                      :horizontal="true">
                            <b-form-input v-if="prop.sqltype == 'varchar' && !prop.hasOwnProperty('reference')" :key="'in_'+prop.csfield"
                                          id="input-1"
                                          v-model="Row[prop.csfield.toLowerCase()]"
                                          type="text"></b-form-input>

                            <b-form-input v-if="prop.inputtype == 'int' && !prop.hasOwnProperty('reference')" :key="'in_'+prop.csfield"
                                          id="input-1"
                                          v-model="Row[prop.csfield.toLowerCase()]"
                                          type="text"></b-form-input>



                            <BitProp v-if="prop.inputtype == 'bitprop'" v-model="Row[prop.csfield.toLowerCase()]" v-bind:ShowUndefined=true></BitProp>


                            <b-form-checkbox v-if="prop.inputtype == 'bit'" :key="'in_'+prop.csfield"
                                             id="'input-'+index"
                                             v-model="Row[prop.csfield.toLowerCase()]"
                                             type="checkbox">
                            </b-form-checkbox>

                            <AyListSelect v-if="prop.hasOwnProperty('reference')"
                                          :reference="prop.reference"
                                          style="width: 75%"
                                          :isDisabled=false
                                          :filters="[]"
                                          v-model="Row[prop.csfield.toLowerCase()]"
                                          @searchchange="UpdateSelectList(searchtext)">
                            </AyListSelect>




                        </b-form-group>
                    </template>

                    <b-card-text class="text-danger">{{ErrorResponse}}</b-card-text>



                    <template v-slot:footer>
                        
                        <b-button variant="success"  @click="SetFilter()">Filter</b-button>
                            
                        <b-button variant="outline-primary" class="float-right" @click="RemoveFilters">
                            
                            <font-awesome-icon size="lg" icon="filter"  style="color:dodgerblue" />
                            <font-awesome-icon size="xs" icon="minus-circle" transform="shrink-4 down-3 left-6"  />
                        </b-button>

                    </template>

                </b-card>

            </b-card-group>

        </div>
    </div>





</template>

<script>
    import axios from 'axios';    
    import BitProp from './BitProp.vue';
    import AyListSelect from './ay-list-select';



    export default {
        components: {            
            AyListSelect,
            BitProp
        },
        props: {
            Rifentity: {
                type: Number,
                required: true
            },

        },
        data() {
            return {                
                errors: [],
                isLoading: true,
                ShowModal: false,
                Elements: Object,
                ErrorResponse: '',
                Filter: {},
                ComboElements: {},
                Row: Object,
                SelectedEntity: Object,
                SwiDel: false
            };
        },
        watch: {
            Rifentity: {
                immediate: true,
                handler(newVal) {
                    this.SwiDel = false;
                    this.isLoading = true;
                    //this.Elements = [];
                    this.Row = {};
                    //console.log('EditAna : watch Rifentity  ');
                    //console.log(newVal);
                    this.SelectedEntity = this.$store.state.Entity.find(E => E.id === this.Rifentity);

                    var tElements = this.GetElements(newVal);
                                        
                    this.GetAllComboData(tElements);

                    tElements.forEach(element => this.SetElementProps(element));

                    this.Elements = tElements;
                    // Non rimuovere questo commento
                    console.log('tElements aggiunti');

                    //console.log('sto richiedendo il template - Filterana : ' + newVal + ' - ' + oldVal);
                    this.GetTemplate();
                  


                }
            },

            ShowModal: {
                immediate: true,
                handler(newVal) {
                    if (newVal == true) {
                        this.$bvModal.show('id_modal_edit');
                    }
                }
            }
        },
        methods: {
            GetElements: function (RifEntity) {
                // Ritorda la definizione della classe, se non � in memoria la scarica dal server

                var tElements = this.$store.state.ElementsAll.filter(E => E.fkmanagedentity === RifEntity);

                // Filtro Elements con filtro
                return tElements.filter(E => E.fkentityfilterdisplay != 0);
                //entityfilterdisplay	0



            },
            GetEntity: function (parRifEntity) {
                // Ritorda la definizione della classe
                if (this.$store.state.Entity.find(E => E.id === parRifEntity)) {
                    //console.log('GetEntity trovato : ' + parRifEntity);
                    return this.$store.state.Entity.find(E => E.id === parRifEntity);
                }
                else {
                    //console.log('GetEntity non trovato :' + parRifEntity);
                    return undefined;
                }

            },
            GetTemplate() {
                // Get Template
                
                let self = this;
                //var Link = 'api/' + (self.SelectedEntity.fkcompany == -1 ? '_main' : this.$store.state.Company.csnamespace) + '/' + self.SelectedEntity.csclass + '/template/';
                var Link = 'api/' + self.SelectedEntity.controller + '/createtemplate/';
                axios.get(Link, this.$store.state.Headers)
                    .then(response => {
                        self.Row = response.data.Result;
                        //console.log('ho il response');
                        for (var prop in self.Row) {                            
                            //console.log(prop);
                            self.Row[prop] = undefined;
                        }
                    }).catch(e => {
                        this.errors.push(e);
                    });

            },
            RemoveFilters() {
                var tFilters = [];
                console.log('RemoveFilters');
                this.GetTemplate();
                this.$emit('filter', true, tFilters);
            },
            SetFilter() {
                var i;
                var tFilters = [];
                this.ErrorResponse = '';
                for (i = 0; i < this.Elements.length; i++) {
                    var tElement = this.Elements[i];
                                        
                    var tValue = this.Row[tElement.csfield.toLowerCase()];
                    
                    if ((!tValue) && (tElement.fkentityfilterdisplay == 2)) {
                        this.ErrorResponse = 'Required filter not set';
                        return;
                    }
                    
                    if (tValue === '') { tValue = undefined; }
                    
                    if (tValue != undefined) {                        
                        var Filter = {};
                        Filter.managedEntityElement = tElement.id;
                        Filter.inverse = false;
                        Filter.bigintValues = [];
                        Filter.nvarcharValues = [];
                        
                        var tType = tElement.cstype;
                        //console.log(tType);
                        if ((tType == 'System.Byte') || (tType == 'System.Int16') || (tType == 'System.Int32') || (tType == 'System.Int64')) {                            
                            Filter.bigintValues.push(tValue);
                        }
                        else {
                            Filter.nvarcharValues.push(tValue);
                        }
                        

                        //Filter.bigintValues.push(tValue);                        
                        //this.Filter.nvarcharValues.push(tValue);
                        tFilters.push(Filter);
                    }
                    


                }
                this.$emit('filter', true, tFilters);




            },
         
     
            GetAllComboData: function (parElements) {
                //console.log('GetAllComboData');
                let self = this;
                var tRifEnts = [];
                var tLinks = [];
                var i;
                for (i = 0; i < parElements.length; i++) {

                    if (Object.prototype.hasOwnProperty.call(parElements[i], 'reference')) {
                        console.log(parElements[i].label);
                        var tRifEntEl = parElements[i].reference;
                        var tRifEnt = this.$store.state.ElementsAll.find(E => E.id === tRifEntEl).fkmanagedentity;
                        var tEntity = this.$store.state.Entity.find(E => E.id === tRifEnt);
                        // Creo un array di RifEntity, mi serve quando assegno i response
                        tRifEnts.push(tRifEnt);                        
                        //var Link = 'api/' + (self.SelectedEntity.fkcompany == -1 ? '_main' : this.$store.state.Company.csnamespace) + '/' + tEntity.csclass + '/summary';
                        var Link = 'api/' + tEntity.controller + '/summary';
                        tLinks.push(Link);
                    }
                }
                self.isLoading = true;
                axios.all(tLinks.map(l => axios.get(l, this.$store.state.Headers)))
                    .then(axios.spread(function (...responses) {
                        // all requests are now complete
                        for (i = 0; i < responses.length; i++) {
                            self.ComboElements[tRifEnts[i]] = responses[i].data.Result;
                        }
                        self.isLoading = false;
                    }));

            },
            GetComboData: function (parEntityElement) {

                //console.log('GetComboData field : ' + parEntityElement.csfield);

                if (Object.prototype.hasOwnProperty.call(parEntityElement, 'reference')) {

                    var tRifEntEl = parEntityElement.reference;
                    //var tExist = Object.prototype.hasOwnProperty.call(this.ComboElements, tRifEnt);

                    var tRifEnt = this.$store.state.ElementsAll.find(E => E.id === tRifEntEl).fkmanagedentity;
                    var tEntity = this.$store.state.Entity.find(E => E.id === tRifEnt);

                    //console.log('GetComboData download : ' + tEntity.label);

                    let self = this;

                    //var Link = 'api/' + (self.SelectedEntity.fkcompany == -1 ? '_main' : this.$store.state.Company.csnamespace) + '/' + tEntity.csclass;
                    var Link = 'api/' + tEntity.controller;
                    axios.get(Link, this.$store.state.Headers)
                        .then(response => {
                            var tElenco = response.data.Result;                            
                            self.ComboElements[tRifEnt] = tElenco;                            
                            this.isLoading = false;
                            
                            return tElenco;

                        }).catch(e => {
                            this.errors.push(e)
                            //self.isLoading = false;
                        });
                }


            },
            GetKey: function (parRifEntity) {
                return this.$store.state.ElementsAll.find(E => E.id === parRifEntity).csfield.toLowerCase();
            },
            GetDescription: function (parRifEntity) {
                var tElement = this.$store.state.ElementsAll.find(E => E.id === parRifEntity);
                var tKey = tElement.fkmanagedentity;
                return this.$store.state.ElementsAll.find(E => E.fkmanagedentity === tKey && E.insummary == true).csfield.toLowerCase();
            },
            GetCombo: function (parRifEntityElement) {

                var tRifEnt = this.$store.state.ElementsAll.find(E => E.id === parRifEntityElement).fkmanagedentity;
                //console.log('GetCombo parRifEntity : ' + tRifEnt);
                var tCombo = this.ComboElements[tRifEnt];
                //console.log('GetCombo lenght : ' + tCombo.length)
                return tCombo;
            },
            SetElementProps: function (element) {
                
                element.inputtype = element.sqltype;
                if (element.csfield.toLowerCase() == 'bitprop') {
                    element.inputtype = 'bitprop';
                }

                if (Object.prototype.hasOwnProperty.call(element, 'reference')) {
                    element.inputtype = 'reference';
                }

            },
            UpdateSelectList() {

            },

  
            onClose() {
                this.isLoading = true;
                this.ShowModal = false;
                this.$emit('hidemodal', false, '');
            }

        },
        mounted() {
            console.log('mounted FiletAna');
            //this.GetRowData();
        }

    }
</script>
